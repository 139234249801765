import { BrowserModule } from '@angular/platform-browser'
import { CookieService } from 'ngx-cookie-service'
import { NgxGfmoneyMessagesModule } from '@yuri.karkhachev/ngx-gfmoney-messages'
import { NgxGfmoneyLibModule } from '@yuri.karkhachev/ngx-gfmoney-lib'
import { NgxMaskModule } from 'ngx-mask'
import { NgxCaptchaModule } from 'ngx-captcha'
import { ModalModule } from 'ngx-bootstrap/modal'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { CustomerNavigationModule } from './customer-navigation/customer-navigation.module'
import { CurrencyPipe, DatePipe } from '@angular/common'
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core'
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ApiInterceptor } from '@shared/services/api.interceptor'
import { environment } from '@environments/environment'
import { AppRoutingModule } from '@src/app/app-routing.module'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { StoreModule } from '@ngrx/store'
import { appStore } from '@store/app.state'
import { appReducer } from '@store/app.reducer'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { AuthService } from '@shared/services/auth.service'
import { ApplicationConfig, DEFAULT_CURRENCY_CODE, importProvidersFrom, LOCALE_ID } from '@angular/core'

import { defineLocale } from 'ngx-bootstrap/chronos'
import { daLocale } from 'ngx-bootstrap/locale'

import { registerLocaleData } from '@angular/common'
import localeDa from '@angular/common/locales/da'
import { OldBoardGuard } from '@shared/guards/old-board.guard'
import { AuthGuard } from '@shared/guards/auth.guard'
import { DashboardGuard } from '@shared/guards/dashboard.guard'
registerLocaleData(localeDa, 'da')
defineLocale('da', daLocale)

// istanbul ignore next
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json')
const INTERCEPTOR_PROVIDER = { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }

export const appConfig: ApplicationConfig = {
  providers: [
    CurrencyPipe,
    DatePipe,
    INTERCEPTOR_PROVIDER,
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      BsDatepickerModule.forRoot(),
      CustomerNavigationModule,
      ModalModule.forRoot(),
      NgxCaptchaModule,
      NgxGfmoneyLibModule.forRoot({ servicesUrl: environment.servicesApiHost }),
      NgxGfmoneyMessagesModule.forRoot({
        allowedFileTypes: 'image/jpeg,image/png,.pdf,.txt',
        apiUrl: `${environment.apiHost}`,
        brand: environment.companyName,
        dateFormat: 'yyyy-MM-dd HH:mm',
        homePagePath: '/dashboard-visa',
      }),
      NgxMaskModule.forRoot(),
      TooltipModule.forRoot(),
      StoreModule.forRoot({ [appStore]: appReducer }),
      StoreDevtoolsModule.instrument({ logOnly: !environment.production, maxAge: 25 }),
    ),
    AuthService,
    CookieService,
    OldBoardGuard,
    AuthGuard,
    DashboardGuard,
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'kr' },
    provideTranslateService({
      defaultLanguage: 'da',
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient] },
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}
