// istanbul ignore file
import { NgModule } from '@angular/core'
import { RouterModule, Routes, PreloadAllModules } from '@angular/router'
import { ErrorPageComponent } from '@visa/error-page/error-page.component'
import { AuthGuard } from '@shared/guards/auth.guard'
import { FlowLayoutComponent } from './flow/shared/flow-layout/flow-layout.component'

const routes: Routes = [
  { path: '', loadChildren: () => import('./flow/flow.module').then(m => m.FlowModule) },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'dashboard-visa',
    loadChildren: () => import('./dashboard-visa/dashboard-visa.module').then(m => m.DashboardVisaModule),
    canLoad: [AuthGuard],
  },
  { path: '**', component: FlowLayoutComponent, children: [{ path: '', component: ErrorPageComponent }] },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
