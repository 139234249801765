import { Component } from '@angular/core'
import { CustomerNavigationService } from './customer-navigation/customer-navigation.service'
import { RouterOutlet } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(private customerNavigationService: CustomerNavigationService) {
    this.customerNavigationService.init()
  }
}
